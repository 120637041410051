
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Group } from "@/entities/group";
import store from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import { getWeekdayStr } from "@/utils/date";
import { registerLabel } from "@/api/school";
import { createRoom } from "@/api/room";
import {
  createRoomRegularlyReservation,
  createRoomReservation
} from "@/api/room_reservation";
import { School } from "@/entities/school";
import { Target } from "@/entities/target";
import { saveErrorLog } from "@/api/error";
import { Getter, State } from "@/store/helper";
import { Label, LabelData } from "@/entities/label";
import {
  convertToUnixtimeFromDateAndTime,
  getCurrentUnixtime
} from "@/utils/date";

const NEW_LABEL_PLACEHOLDER = "_blank";

type RegularPatternOption = {
  id: "none" | "day" | "week" | "month";
  text: string;
};
type BlankLabel = {
  data: { name: string; label: string };
  ref: { id: string };
};

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextArea,
    MTextField
  },
  emits: ["close"],
  props: {
    school: Object,
    groups: Array,
    targets: Array
  }
})
export default class MrAddModal extends Vue {
  school?: School;
  selectedGroupId = "";
  selectedTargetId = "";
  selectedLabelId = "";
  groups: Group[] = [];
  targets: Target[] = [];
  name = "学習室";
  withNotification = false;
  isReservation = false;
  reserveDay = "";
  reserveTime = "18:00";
  existDeletionTime = false;
  deletionDay = "";
  selectedRegularPattern: "none" | "day" | "week" | "month" = "none";
  endDate = "";
  endTime = "";
  endReserveDay = "";
  endReserveTime = "22:30";

  @Getter("isAdmin") isAdmin!: boolean;

  @State("labels", "room") labels!: Label[];

  get labelsAndBlankChoice(): (Label | BlankLabel)[] {
    return this.school
      ? [
          ...this.labels,
          {
            data: {
              name: `${this.name}（新規）`,
              label: `${this.name}（新規）`
            },
            ref: { id: NEW_LABEL_PLACEHOLDER }
          }
        ]
      : [...this.labels];
  }

  get regularPatternOptions(): RegularPatternOption[] {
    if (this.reserveDay === "") {
      return [
        {
          id: "none",
          text: "なし"
        }
      ];
    }
    const dayData = dayjs(this.reserveDay).locale("ja");
    const monthText = `毎月${dayData.date()}日`;
    const weekText = `毎週${getWeekdayStr(dayData.day())}曜日`;
    return [
      {
        id: "none",
        text: "なし"
      },
      {
        id: "month",
        text: monthText
      },
      {
        id: "week",
        text: weekText
      },
      {
        id: "day",
        text: "毎日"
      }
    ];
  }

  get selectedTarget(): Target | null {
    return (
      this.targets.find(target => target.ref.id === this.selectedTargetId) ??
      null
    );
  }

  get selectedGroup(): Group | null {
    return (
      this.groups.find(group => group.ref.id === this.selectedGroupId) ?? null
    );
  }

  get selectedLabel(): Label | null {
    return (
      this.labels.find(label => label.ref.id === this.selectedLabelId) ?? null
    );
  }

  get modalTitle(): string {
    return this.isReservation ? "開室予約を登録" : "学習室を作成";
  }

  get mainButtonTitle(): string {
    return this.isReservation ? "開室予約" : "開室する";
  }

  get isValidName() {
    return this.name.length > 0;
  }

  get validReserveDay(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.reserveDay);
  }

  get validReserveTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.reserveTime);
  }

  get validDeletionDay(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.deletionDay);
  }

  get isValidReservation() {
    if (!this.isReservation) {
      return true;
    }

    if (this.existDeletionTime) {
      return (
        this.validReserveDay && this.validReserveTime && this.validDeletionDay
      );
    } else {
      return this.validReserveDay && this.validReserveTime;
    }
  }

  get validData() {
    if (this.endDate && !this.endTime) {
      // 終了予定日付が入力されていて、終了予定時分が入力されていない場合は、開室ボタンを非活性にする
      return (
        this.isValidName &&
        this.isValidReservation &&
        (!!this.selectedLabel || this.selectedLabelId === NEW_LABEL_PLACEHOLDER)
      );
    } else if (this.endTime && !this.endDate) {
      // 終了予定時分が入力されていて、終了予定日付が入力されていない場合は、開室ボタンを非活性にする
      return (
        this.isValidName &&
        this.isValidReservation &&
        (!!this.selectedLabel || this.selectedLabelId === NEW_LABEL_PLACEHOLDER)
      );
    } else if (this.isReservation) {
      if (this.selectedRegularPattern !== "none") {
        return (
          this.isValidName &&
          this.isValidReservation &&
          !!this.selectedLabel &&
          !!this.endReserveTime
        );
      } else {
        return (
          this.isValidName &&
          this.isValidReservation &&
          !!this.selectedLabel &&
          ((!!this.endReserveTime && !!this.endReserveDay) ||
            (!this.endReserveTime && !this.endReserveDay))
        );
      }
    } else {
      return (
        this.isValidName &&
        this.isValidReservation &&
        (!!this.selectedLabel || this.selectedLabelId === NEW_LABEL_PLACEHOLDER)
      );
    }
  }

  async tapMainButton() {
    if (!this.validData) {
      return;
    }

    // 通常の学習室開催で過去日時が入力されていたら、アラートを表示して早期リターン
    if (
      convertToUnixtimeFromDateAndTime(this.endDate, this.endTime) <
      getCurrentUnixtime()
    ) {
      confirm("閉室予約日時は未来日を入力してください。");
      this.endDate = "";
      this.endTime = "";
      return;
    }

    // 開室予約の学習室開催で過去日時が入力されていたら、アラートを表示して早期リターン
    if (
      convertToUnixtimeFromDateAndTime(
        this.endReserveDay,
        this.endReserveTime
      ) < convertToUnixtimeFromDateAndTime(this.reserveDay, this.reserveTime)
    ) {
      confirm("閉室予定日時は開室予定日時より後の日時を設定してください。");
      this.endReserveDay = "";
      this.endReserveTime = "";
      return;
    }

    // 定期的な開室予約の学習室開催で開室時間より閉室時間の方が小さかったら、アラートを表示して早期リターン(深夜の開催は考慮しない)
    if (
      this.selectedRegularPattern !== "none" &&
      +this.reserveTime.replace(/:/g, "") >=
        +this.endReserveTime.replace(/:/g, "")
    ) {
      confirm("閉室予定時間は開室予定時間より後の時間を設定してください。");
      this.endReserveTime = "";
      return;
    }

    const matchGroups = this.groups.filter(
      group => group.ref.id === this.selectedGroupId
    );

    if (this.isReservation) {
      await this.registerReservation(
        matchGroups.length > 0 ? matchGroups[0] : undefined
      );
    } else {
      await this.createRoom();
    }
  }

  async registerReservation(group?: Group) {
    store.commit("START_LOADING", "開室予約登録中...");

    const reservationDateData = dayjs(
      `${this.reserveDay} ${this.reserveTime}`
    ).locale("ja");
    const reservationTime = reservationDateData.unix();
    if (this.selectedRegularPattern === "none") {
      try {
        // 閉室予約時間が入力されていた場合、unixtimeに変換
        const endTime: number | null =
          !!this.endReserveDay && !!this.endReserveTime
            ? convertToUnixtimeFromDateAndTime(
                this.endReserveDay,
                this.endReserveTime
              )
            : null;
        // label を作成
        await createRoomReservation(
          this.name,
          group ? group.data.title : "全ての生徒",
          this.withNotification,
          reservationTime,
          this.selectedLabel ? [this.selectedLabel.ref] : [],
          endTime,
          group ? group.ref : undefined
        );
      } catch (e) {
        store.commit("END_LOADING");
        alert(e.message);
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to create room reservation"
        );
        return;
      }
    } else {
      const deletionTime = this.existDeletionTime
        ? dayjs(this.deletionDay).locale("ja").add(1, "day").unix()
        : undefined;
      try {
        await createRoomRegularlyReservation(
          this.name,
          group ? group.data.title : "全ての生徒",
          this.withNotification,
          reservationTime,
          this.selectedRegularPattern as "day" | "week" | "month",
          this.reserveTime,
          this.selectedLabel ? [this.selectedLabel.ref] : [],
          this.endReserveTime,
          reservationDateData.day(),
          reservationDateData.date(),
          deletionTime,
          group ? group.ref : undefined
        );
      } catch (e) {
        store.commit("END_LOADING");
        alert(e.message);
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to create room regularly reservation"
        );
        return;
      }
    }

    store.commit("END_LOADING");

    alert("開室予約の登録が完了しました");
    this.$router.go(0);
  }

  async createRoom() {
    if (!this.isAdmin && !this.school) {
      return;
    }

    store.commit("START_LOADING", "学習室作成中...");
    try {
      let labelDocId;
      if (this.school && this.selectedLabelId === NEW_LABEL_PLACEHOLDER) {
        // school.labels の新規作成 / 追加
        const newLabelData: LabelData = {
          type: "room",
          order: 10,
          name: this.name
        };
        const res = await registerLabel(this.school.ref.id, newLabelData);
        store.commit("room/SET_LABELS", [
          ...this.labels,
          {
            ref: res.ref,
            data: newLabelData
          }
        ]);
        labelDocId = res.labelDocId;
      } else {
        labelDocId = this.selectedLabelId;
      }

      // 閉室予約時間が入力されていた場合、unixtimeに変換
      const endTime: number | null =
        !!this.endDate && !!this.endTime
          ? convertToUnixtimeFromDateAndTime(this.endDate, this.endTime)
          : null;

      // room の作成
      await createRoom(
        this.name,
        this.withNotification,
        [
          {
            schoolDocId: this.school?.ref.id,
            labelDocId
          }
        ],
        endTime,
        this.selectedGroupId,
        this.selectedTargetId
      );

      store.commit("END_LOADING");
    } catch (e) {
      alert(
        `学習室の作成に失敗しました。時間を置いて再度実行してみてください。\n\n${e}`
      );
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to create room"
      );
    }

    this.close();
  }

  created() {
    this.reserveDay = dayjs().locale("ja").add(1, "day").format("YYYY-MM-DD");
    this.endReserveDay = this.reserveDay;
    if (this.school) {
      this.name = `${this.school.data.name}の学習室`;
    } else {
      this.selectedTargetId = this.targets?.[0]?.ref.id ?? "";
    }
    this.selectedLabelId = this.labels?.[0]?.ref.id ?? NEW_LABEL_PLACEHOLDER;
  }

  close() {
    this.$emit("close");
  }
}
